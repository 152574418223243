import styled from "styled-components";
import { COLOR } from "./constant";

export const Container = styled.div`
  padding: 0;
  margin: 0;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: ${COLOR.whiteBg};
`;

export const AdminHeader = styled.div`
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  display: grid;
  padding: 15px;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 1;
  background-color: ${COLOR.deepBlue};
  z-index: 9999999 !important;
`;

export const HeaderUser = styled.div`
  grid-column: 3/12;
  grid-row: 1;
  color: ${COLOR.white};
  padding-top: 8px;
  font-weight: 400;
  text-align: right;
  font-size: 13px;
  margin-right: 20px;
  user-select: none;
`;

export const LogOutUserHolder = styled.div`
  grid-column: 12/13;
  grid-row: 1;
`


export const HeaderHamburger = styled.div`
  grid-column: 1/2;
  grid-row: 1;
  color: ${COLOR.white};
  cursor: pointer;
`;


export const FormWrapper = styled.div`
   padding: 20px;
   padding-top: 0;
   min-width: 300px;
   max-width: 400px;
   margin-left: auto;
   margin-right: auto;
   margin-top: 60px;
   margin-bottom: 40px;
   border: 0.5px solid ${COLOR.border};
   background-color: #f5f5f5;
   border-radius: 0;
   border: 0.5px solid ${COLOR.deepBlue};
   -webkit-box-shadow: 1px -3px 2px -1px #d1d1d1; 
box-shadow: 1px -3px 2px -1px #d1d1d1;
`
