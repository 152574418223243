
import { useForm } from 'react-hook-form';
import { FormWrapper } from '../styles/shared/shared';
import { createBillService } from '../services/createBillService';

const CreateBill = (props: any) => {
    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const createNewBill = async (data: any) => {
        props.setIsSubmit(true);
        data.Amount = +data.Amount
        const response = await createBillService(data);
        if (response.success) {
            props.showNotification(true, response.message)
            reset()
            props.setIsSubmit(false);
            return
        };
        props.showNotification(false, response.message)
        props.setIsSubmit(false);
        return
    };


    return (
        <div style={{ padding: '20px' }}>
            <FormWrapper>
                <form onSubmit={handleSubmit(createNewBill)}>

                    <h3 style={{ color: '#0A3EA4', borderBottom: '0.5px solid #d1d1d1', fontWeight: 400, width: '100%', textAlign: 'center', paddingBottom: '20px' }}>
                        Create New Bill
                    </h3>

                    <div style={{ marginBottom: '5px' }}>
                        <label>Customer email</label>
                        <input type="email" className='inputField' {...register("Email", { required: true, pattern: /^\S+@\S+$/i })} />
                        {errors.Email && <div className='errorText'>Customer email must be valid</div>}
                    </div>

                    <div style={{ marginBottom: '5px' }}>
                        <label>First name</label>
                        <input type="text" className='inputField' {...register("FirstName", { required: true })} />
                        {errors.FirstName && <div className='errorText'>Customer first name is required</div>}
                    </div>

                    <div style={{ marginBottom: '5px' }}>
                        <label>Last name</label>
                        <input type="text" className='inputField' {...register("LastName", { required: true })} />
                        {errors.LastName && <div className='errorText'>Customer last name is required</div>}
                    </div>

                    <div style={{ marginBottom: '5px' }}>
                        <label>Payee name <small><em>(e.g SCOTIA SCENE VISA)</em></small></label>
                        <input type="text" className='inputField' {...register("PayeeName", { required: true })} />
                        {errors.PayeeName && <div className='errorText'>Payee name is required</div>}
                    </div>

                    <div style={{ marginBottom: '5px' }}>
                        <label>Payee Code</label>
                        <input type="text" className='inputField' {...register("PayeeCode", { required: true })} />
                        {errors.PayeeCode && <div className='errorText'>Payee code is required</div>}
                    </div>

                    <div style={{ marginBottom: '5px' }}>
                        <label>Amount <small><em>Amount to send</em></small></label>
                        <input type="number" className='inputField' min="0" {...register("Amount", { required: true })}  />
                        {errors.Amount && <div className='errorText'>Valid amount is required</div>}
                    </div>

                    <div style={{ marginBottom: '5px' }}>
                        <label>Payee Account Number</label>
                        <input type="text" className='inputField' {...register("PayeeAccountNumber", { required: true })} />
                        {errors.PayeeAccountNumber && <div className='errorText'>Valid payee account number is required</div>}
                    </div>

                    <div style={{ marginBottom: '5px' }}>
                        <label>Description <div style={{ fontSize: '9px', marginTop: '5px' }}><em>OPTIONAL: A brief description about the transaction provided by the merchant</em></div></label>
                        <input type="text" className='inputField' {...register("Description", { required: false })} />
                        {errors.Description && <div className='errorText'>Valid payee account number is required</div>}
                    </div>


                    <div style={{ marginTop: '20px' }}>
                        <button type="submit" className='submitButton' disabled={props.isSubmit}>
                            {props.isSubmit ? 'Processing' : 'Submit'}
                        </button>
                    </div>
                </form>
            </FormWrapper>
        </div>
    )
}

export default CreateBill;
