import axios from "axios";
import { getBaseUrl } from "../utils/base-url";
import { ERROR_MSG } from "../styles/shared/constant";

const API_URL = getBaseUrl();
export const loginService = async (data: {username: string, password: string}) => {
  const endpoint = `${API_URL}/authentications/login-user`;
  try {
    const response = await axios.post(endpoint, data);
    return response.data;
  } catch (error) {
    return {
      success: false,
      data: null,
      message: ERROR_MSG,
    };
  }
};
