import  { createContext, useState } from 'react'
import { COLOR } from '../styles/shared/constant';
import { NotificationBox, NotificationText } from '../styles/shared/notification';
import { HeaderComponent } from '../components';
import { AddCurrency, AddExchange, CreateBill, LoginAdmin } from '.';

export const AuthContext = createContext(null);

const HomePage = () => {
  const [selectedPage, setSelectedPage] = useState('login')
  const [responseData, setResponseData]: any = useState(null)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [msg, setMsg ]: any = useState(null)
  const [isSubmit, setIsSubmit] = useState(false)


  // LATER: refactor code to have a notification class
  const showNotification = (success: boolean, message: string) => {
    setMsg({success: success, message: message})
    setTimeout(() => {
      setMsg(null);
    }, 2200)
  }

  const logoutUser = () => {
    setIsLoggedIn(false)
    setResponseData(null)
    setSelectedPage('login')
  }

  return (
    <AuthContext.Provider value={responseData}>
    <div style={{ padding: '10px' }}>
      

      {(!isLoggedIn && selectedPage==='login') &&
        <LoginAdmin setResponseData={setResponseData}  setIsLoggedIn={setIsLoggedIn} setIsSubmit={setIsSubmit} isSubmit={isSubmit} showNotification={showNotification} setSelectedPage={setSelectedPage} />
      }
      {
        isLoggedIn &&
        <div style={{width: '100%'}}>
          <HeaderComponent responseData={responseData} logoutUser={logoutUser} setSelectedPage={setSelectedPage} selectedPage={selectedPage} setIsSubmit={setIsSubmit} />
         { selectedPage === 'createBill' && <CreateBill showNotification={showNotification} setResponseData={setResponseData} setIsSubmit={setIsSubmit} isSubmit={isSubmit}  />}
         { selectedPage === 'addCurrency' && <AddCurrency  setIsSubmit={setIsSubmit} isSubmit={isSubmit} showNotification={showNotification}  /> }
         { selectedPage === 'setRate' && <AddExchange setIsSubmit={setIsSubmit} isSubmit={isSubmit} showNotification={showNotification} /> }
        </div>

      }

      {
        msg &&
        <NotificationBox style={{ background: msg.success ? COLOR.green : COLOR.red }}>
          <NotificationText>
            {msg.message}
          </NotificationText>
        </NotificationBox>
      }
       </div>
       </AuthContext.Provider>
   
   
  )
}

export default HomePage