import { HomePage } from './pages';
import { Container } from './styles/shared/shared';

function App() {
  return (
   <Container>
    <HomePage />
    </Container>
  );
}

export default App;
