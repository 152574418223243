export const COLOR = {
    border: '#d1d1d1',
    whiteBg: '#FFFFFF',
    deepBlue: '#0A3EA4',
    gold: '#B39132',
    white: '#FFFFFF',
    black: '#000000',
    green: '#0abf53',
    red: '#dd4b39',
}

export const ERROR_MSG = {
    clientError: "Client error: check your internet"
}