import styled from "styled-components";
import { COLOR } from "../shared/constant";

export const LoginFormWrapper = styled.div`
   padding: 20px;
   padding-top: 0;
   width: 300px;
   margin-left: auto;
   margin-right: auto;
   margin-top: 10%;
   border: 0.5px solid ${COLOR.border};
   background-color: #f5f5f5;
   border-radius: 0;
   border: 0.5px solid ${COLOR.deepBlue};
   -webkit-box-shadow: 1px -3px 2px -1px #d1d1d1; 
   box-shadow: 1px -3px 2px -1px #d1d1d1;
`

