import { AddCurrencyWrapper, CurrencyBodyWrapper, CurrencyListWrapperLI, CurrencyListWrapperLS, CurrencyListWrapperRS, CurrencyListWrapperUL, CurrencyWrapperLeft, CurrencyWrapperRight } from '../styles/operation/add-currency';
import { useEffect, useState } from 'react';
import { addCurrency, getAddedCurrencies, getCurrencies, removeAddedCurrency } from '../services/operationService';

const AddCurrency = (props: any) => {
    const { setIsSubmit, isSubmit, showNotification } = props
    const [showAddCurrencyForm, setShowAddCurrencyForm] = useState(false)
    const [responseData, setResponseData]: any = useState(null)
    const [currencyListData, setCurrencyListData]: any = useState(null)
    const [checkedEntries, setCheckedEntries] = useState(new Set())
    const [isLoading, setIsloading] = useState(true)


    const getAllCurrency = async () => {
        setIsloading(true)
        const currencies = await getCurrencies()
        if (currencies.success) {
            setResponseData(currencies.data)
            return setIsloading(false)
        } else {
            showNotification(false, currencies.message)
            return setIsloading(false)
        }
    }

    const addSelectedCurrencyToSet = (isChecked: boolean, currencyCode: any) => {
        if (!isChecked && checkedEntries.has(currencyCode)) {
            checkedEntries.delete(currencyCode)
            setCheckedEntries(checkedEntries)
            return showNotification(false, `${currencyCode} deselected`);
        } else {
            checkedEntries.add(currencyCode)
            setCheckedEntries(checkedEntries)
            return showNotification(true, `${currencyCode} selected`);
        }

    }

    const handleCurrencyOnchange = async (forAction: boolean, event: any, currency: any) => {
        if (forAction && checkedEntries.size > 0) {
            setIsSubmit(true)
            const currencyToArr: any = Array.from(checkedEntries)
            const response = await addCurrency({ currencies: currencyToArr })
            if (response.success) {
                getSavedCurrencies()
                checkedEntries.clear(); setCheckedEntries(checkedEntries);
                showNotification(true, `${response.message}`);
                setShowAddCurrencyForm(false)
                return setIsSubmit(false)
            } else {
                setShowAddCurrencyForm(true)
                showNotification(false, `${response.message}`);
                return setIsSubmit(false)
            }

        }
        const isChecked = event?.target?.checked;
        return addSelectedCurrencyToSet(isChecked, currency?.code)
    }

    const toggleAddCurrency = () => {
        getAllCurrency()
        setShowAddCurrencyForm(!showAddCurrencyForm)
    }

    const getSavedCurrencies = async () => {
        const response = await getAddedCurrencies()
        if (response.success) {
            setCurrencyListData(response.data)
            return setIsloading(false)
        }
        showNotification(false, response.message)
        return setIsloading(false)
    }


    const removeCurrency = async (currency: any) => {
        const isConfirmed = window.confirm(`Removing the ${currency.name} (${currency.code}) will remove all exchange rates associated with this currency. Do you want to proceed ?`)
        if(!isConfirmed) return;

        setIsSubmit(true)
        const data = {
            currencyId: currency._id,
            // currencyCode: currency.code
        }
       const response = await removeAddedCurrency(data)
       if(response.success){
        getSavedCurrencies();
        showNotification(true, response.message)
        return setIsSubmit(false)
       }else{
        showNotification(false, response.message)
        return setIsSubmit(false)
       }
    }


    useEffect(() => {
        getSavedCurrencies()
    }, [])


    return (
        <div style={{ padding: '20px' }}>
            <AddCurrencyWrapper>
                {!showAddCurrencyForm && <CurrencyWrapperLeft>
                    <div style={{ padding: '10px', borderBottom: '0.5px solid #d1d1d1', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                       
                        <h3 style={{ color: '#0A3EA4', borderBottom: '0.5px solid #d1d1d1', fontWeight: 400, width: '100%', textAlign: 'center', paddingBottom: '20px' }}>
                        Your added currencies

                            <button type="submit" onClick={toggleAddCurrency} className='submitButton' disabled={isSubmit} style={{ width: '120px', marginLeft: '20px', fontSize: '14px', height: '30px', padding: '5px' }}>
                            {showAddCurrencyForm ? 'Cancel' : '+ Add more'}
                            </button>
                        </h3>
                    </div>
                    <div style={{ width: '100%' }}>
                        <ul style={{ listStyle: 'none' }}>
                            {
                                currencyListData && currencyListData.map((currency: any, index: number) => (
                                    <li key={index}>
                                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', alignContent: 'center', borderBottom: '0.5px solid #d1d1d1', padding: '10px',  }}>
                                            <div style={{ width: '10%' }}>
                                                {index + 1}.
                                            </div>
                                            <div style={{ width: '20%' }}>
                                            <img src={currency.flag} alt={currency.code} width={22}  />
                                            </div>
                                            <div style={{ width: '50%' }}>
                                                <strong> {currency.code} </strong>
                                            </div>

                                            <div style={{ width: '20%' }}>
                                                <button type="submit" onClick={() => removeCurrency(currency)} className='submitButton' disabled={isSubmit} style={{ width: '30px', height: '30px', padding: '5px', background: '#e4002b' }}>
                                                    {'X'}
                                                </button>
                                            </div>
                                        </div>
                                    </li>
                                ))
                            }
                            {
                            !isLoading && currencyListData?.length === 0 && <div> <h5>No added currency yet</h5></div>
                            }
                            {
                              isLoading &&  <div style={{width: '100%', textAlign: 'center'}}> <h5 style={{color: 'green'}}><strong> Loading... </strong></h5></div>
                            }
                        </ul>
                    </div>
                </CurrencyWrapperLeft>
                }

                {showAddCurrencyForm && <CurrencyWrapperRight>
                    <CurrencyBodyWrapper>
                        <div style={{ padding: '10px', borderBottom: '0.5px solid #d1d1d1', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                          
                            <h3 style={{ color: '#0A3EA4', borderBottom: '0', fontWeight: 400, width: '100%', textAlign: 'center', }}>
                            <button type="submit" onClick={toggleAddCurrency} className='submitButton' disabled={isSubmit} style={{ width: '80px', marginRight: '20px', fontSize: '12px', height: '30px', padding: '5px', background: '#e4002b' }}>
                                {'X Cancel'}
                            </button> 

                            Add New Currency
                        </h3>
                        </div>

                        <div style={{paddingTop: '20px', textAlign: 'center', color: '#0abf53'}}>
                            { isLoading && 'Please wait .... Retrieving currencies list'}
                        </div>
                        <CurrencyListWrapperUL>
                            {responseData && responseData.map((currency: any, index:number) => (
                                <CurrencyListWrapperLI key={index} style={{width: '230px'}}>
                                    <CurrencyListWrapperLS>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', fontSize: '12px' }}>
                                            <img src={currency.flag} alt={currency.code} width={22} />
                                            <span style={{ marginLeft: '10px', fontWeight: 500 }}>{currency.code}</span>
                                            <span style={{ fontSize: '11px', marginLeft: '10px' }}> / {currency.name}</span>
                                        </div>
                                        <div style={{ marginLeft: '30px', display: 'flex', alignItems: 'center', justifyContent: 'start', fontSize: '11px' }}>
                                            <span> <em>{currency.countryCode} {currency.country}</em> </span>
                                        </div>
                                    </CurrencyListWrapperLS>
                                    <CurrencyListWrapperRS>
                                        <input type="checkbox" onChange={(event) => handleCurrencyOnchange(false, event, currency)} className='rounded-checkbox' />
                                    </CurrencyListWrapperRS>
                                </CurrencyListWrapperLI>
                            ))
                            }
                        </CurrencyListWrapperUL>

                        <div style={{ width: '100%', textAlign: 'center' }}>
                            <button type="submit" className='submitButton' onClick={() => handleCurrencyOnchange(true, null, null)} disabled={isSubmit} style={{ width: '200px', }}>
                                {isSubmit ? 'Processing' : 'Submit'}
                            </button>
                        </div>
                    </CurrencyBodyWrapper>
                </CurrencyWrapperRight>}

            </AddCurrencyWrapper>
        </div>
    );
}

export default AddCurrency;