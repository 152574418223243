import { useForm } from "react-hook-form";
import { AddCurrencyWrapper, CurrencyListWrapperLI, CurrencyListWrapperUL, RateListRow1Col1, RateListRow1Col1Flex, RateListRow1Col2 } from "../styles/operation/add-currency"
import { FormWrapper } from "../styles/shared/shared";
import { useContext, useEffect, useState } from "react";
import { fetchAllRates, getAddedCurrencies, removeAddedExchangeRate, upsertExchangeRate } from "../services/operationService";
import { AuthContext } from "./HomePage";


const AddExchange = (props: any) => {
    const value: any = useContext(AuthContext);  
    const { isSubmit, setIsSubmit, showNotification } = props
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [responseData, setResponseData]: any = useState(null)
    const [rateResponseData, setRateResponseData]: any = useState(null)
    const [showAddRate, setShowAddRate] = useState(false)
    const [isLoading, setIsloading] = useState(true)


    const getSavedCurrencies = async () => {
        const response = await getAddedCurrencies()
        if (response.success) {
            setResponseData(response.data)
            return setIsloading(false)
        }
        showNotification(false, response.message)
        return setIsloading(false)
    }

    const  getAllRate = async () => {
        const response = await fetchAllRates()
        if (response.success) {
            setRateResponseData(response.data)
            setShowAddRate(false);
            return setIsloading(false)
        }
        showNotification(false, response.message)
        return setIsloading(false)
    }

    const addNewRate = async (data: any) => {
        setIsSubmit(true)
        data['token'] = value?.data?.token;
       
        const response = await upsertExchangeRate(data)
        if (response.success) {
            showNotification(true, response.message)
            getAllRate();
            return setIsSubmit(false)
        }
        showNotification(false, response.message)
        return setIsSubmit(false)    
    }

    useEffect(() => {
        getAllRate()
        getSavedCurrencies()
    }, [])

    const toggleShowAddRate = () => {
        return setShowAddRate(!showAddRate)
    }

    const removeExchangeRate = async (rate: any) => {
        const isConfirm = window.confirm(`Are you sure you want to delete this? Deleted data may never be retrieved`)
       if(!isConfirm) return

       setIsSubmit(true)
       const data = {rateId: rate.rateId}
      
      const response = await removeAddedExchangeRate(data)
      if(response.success){
       getAllRate()
       showNotification(true, response.message)
       return setIsSubmit(false)
      }else{
       showNotification(false, response.message)
       return setIsSubmit(false)
      }

    }

    return (
        <div style={{ padding: '20px' }} >
            <AddCurrencyWrapper>
                {
                    showAddRate &&
                    <FormWrapper>
                        <h3 style={{ color: '#0A3EA4', borderBottom: '0.5px solid #d1d1d1', fontWeight: 400, width: '100%', textAlign: 'center', paddingBottom: '20px' }}>
                            <button type="submit" onClick={toggleShowAddRate} className='submitButton' disabled={isSubmit} style={{ width: '80px', marginRight: '20px', fontSize: '12px', height: '30px', padding: '5px', background: '#e4002b' }}>
                                {'X Cancel'}
                            </button> 

                        Add/Edit Exchange Rates

                        </h3>
                        <form onSubmit={handleSubmit(addNewRate)}>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>
                                <div>
                                    <span style={{ marginRight: '10px' }}>

                                        <input type="number" className='inputField' step="any" value={1} readOnly style={{ width: '100px', backgroundColor: '#f5f5f5', color: '#000000', fontWeight: '900' }} />

                                    </span>
                                    <span>
                                        <select {...register("fromCurrencyId", { required: true })} className='inputField' style={{ width: '100px' }} >
                                            <option value={''}>Select</option>
                                            { responseData && responseData.map((currency:any, index: number) => (<option  key={index} value={currency._id}>{currency.code}</option>)) }
                                        </select>

                                        <div style={{ width: '100%', textAlign: 'right'}}>
                                        {errors.fromCurrencyId && <div className='errorText'>Select Currency</div>}
                                         </div>
                                    </span>
                                </div>

                                <div style={{ margin: '10px 0 20px 0', color: '#f92c11' }}>
                                    <em><strong> is equal to </strong></em>
                                </div>

                                <div>
                                    <span style={{ marginRight: '10px' }}>
                                        <input type="number" className='inputField' step="any" placeholder="0.00" {...register("exchangeRate", { required: true })} style={{ width: '100px' }} />
                                        
                                    </span>

                                    <span>
                                        <select {...register("toCurrencyId", { required: true })} className='inputField' style={{ width: '100px' }}>
                                            <option value={''}>Select</option>
                                            { responseData && responseData.map((currency:any, index: number) => (<option key={index} value={currency._id}>{currency.code}</option>)) }
                                        </select>
                                    </span>

                                    <div style={{ width: '100%'}}>
                                        {errors.exchangeRate && <span className='errorText' style={{ textAlign: 'left', }}>Insert valid rate</span>}
                                        {errors.toCurrencyId && <span className='errorText' style={{ textAlign: 'right', marginLeft: '50px' }}>Select Currency</span>}
                                         </div>
                                </div>

                                <div style={{ marginTop: '30px' }}>
                                    <button type="submit" className='submitButton' disabled={isSubmit} style={{ width: '240px' }}>
                                        {isSubmit ? 'Please wait...' : 'Add Rate'}
                                    </button>
                                </div>

                            </div>

                        </form>
                    </FormWrapper>
                }

                {
                    !showAddRate &&

                    <div>
                        <h3 style={{ color: '#0A3EA4', borderBottom: '0.5px solid #d1d1d1', fontWeight: 400, width: '100%', textAlign: 'center', paddingBottom: '20px' }}>
                           You added rates

                            <button type="submit" onClick={toggleShowAddRate} className='submitButton' disabled={isSubmit} style={{ width: '120px', marginLeft: '20px', fontSize: '14px', height: '30px', padding: '5px' }}>
                                {'+ Add New Rate'}
                            </button>
                        </h3>
                        <CurrencyListWrapperUL style={{padding: '0'}}>
                                {
                                    rateResponseData && rateResponseData.map((rate: any, index: number) => (
                                        <CurrencyListWrapperLI key={index} style={{ minWidth: '350px', maxWidth: '500px', padding: '20px 5px' }}>
                                            <RateListRow1Col1 style={{fontSize: '13px'}}>
                                                <RateListRow1Col1Flex>
                                                <span style={{ padding: '0 5px', color: '#dd4b39' }}>
                                                    <button onClick={() => removeExchangeRate(rate)} type="submit" className='submitButton' disabled={isSubmit} style={{ width: '30px', height: '30px', padding: '5px', background: '#e4002b' }}>
                                                        {'X'}
                                                    </button>
                                                </span>
                                                <span><img src={rate.fromCurrency.flag} height={18} alt="" /></span>
                                                <span><strong>1</strong><em>{rate.fromCurrency.code}</em></span>
                                                <span><strong> To </strong></span>
                                               
                                                <span>
                                                    <input type="text" value={rate.exchangeRate} className='inputField' readOnly style={{ width: '100px' }} />
                                                </span>
                                                <span><img src={rate.toCurrency.flag} height={18} alt="" /> </span>
                                                <span> {rate.toCurrency.code}</span>

                                                <button type="button" onClick={toggleShowAddRate}  className='submitButton' disabled={isSubmit} style={{ width: '70px', padding: '5px', fontSize: '12px', fontWeight: 'bold', height: '30px' }}>
                                                    Edit
                                                </button>
                                                </RateListRow1Col1Flex>
                                            </RateListRow1Col1>

                                            <RateListRow1Col2>
                                                
                                            </RateListRow1Col2>
                                        </CurrencyListWrapperLI>
                                    ))
                                }
                                {
                                    isLoading && <div><p style={{fontSize: '14px', color: '#007b31' }}> <strong>Retrieving set rates ...</strong> </p></div> 
                                }
                                {
                                    (!isLoading && rateResponseData?.length === 0) && <div><p style={{fontSize: '14px'}}>No set rate(s) yet! </p></div>
                                }
                        </CurrencyListWrapperUL>
                    </div>
                }
            </AddCurrencyWrapper>

        </div>
    )
}

export default AddExchange;