import axios from "axios";
import { getBaseUrl } from "../utils/base-url";
import { ERROR_MSG } from "../styles/shared/constant";

const API_URL = getBaseUrl();
export const getCurrencies = async () => {
  const endpoint = `${API_URL}/operations/currencies`;
  try {
    const response = await axios.get(endpoint);
    return response.data;
  } catch (error) {
    return {
      success: false,
      data: null,
      message: ERROR_MSG,
    };
  }
};

export const getAddedCurrencies = async () => {
    const endpoint = `${API_URL}/operations/added-currencies`;
    try {
      const response = await axios.get(endpoint);
      return response.data;
    } catch (error) {
      return {
        success: false,
        data: null,
        message: ERROR_MSG,
      };
    }
  };


export const addCurrency = async (data: {currencies: string[]}) => {
    const endpoint = `${API_URL}/operations/add-currency`;
    try {
      const response = await axios.post(endpoint, data);
      return response.data;
    } catch (error) {
      return {
        success: false,
        data: null,
        message: ERROR_MSG,
      };
    }
  };


  export const removeAddedCurrency = async (data: any) => {
    const endpoint = `${API_URL}/exchange-rate/remove-currency-and-associated-rates`;
    try {
      const response = await axios.post(endpoint, data);
      return response.data;
    } catch (error) {
      return {
        success: false,
        data: null,
        message: ERROR_MSG,
      };
    }
  };


//   Exchange Rate Section;

export const upsertExchangeRate = async (param: any ) => {
    const cleanData = {
        token: param.token,
        exchangeRate: +param.exchangeRate,
        fromCurrencyId: param.fromCurrencyId,
        toCurrencyId: param.toCurrencyId,
    }
     
    const endpoint = `${API_URL}/exchange-rate/create-rate`;
    try {
      const response = await axios.post(endpoint, cleanData);
     
      return response.data;
    } catch (error) {
      return {
        success: false,
        data: null,
        message: ERROR_MSG,
      };
    }
  };

  export const fetchAllRates = async () => {
    const endpoint = `${API_URL}/exchange-rate/get-rates`;
    try {
      const response = await axios.get(endpoint);
      return response.data;
    } catch (error) {
      return {
        success: false,
        data: null,
        message: ERROR_MSG,
      };
    }
  };

  export const removeAddedExchangeRate = async (data: { rateId: string}) => {
    const endpoint = `${API_URL}/exchange-rate/remove-rate`;
    try {   
      const response = await axios.post(endpoint, data);
      return response.data;
    } catch (error) {
      return {
        success: false,
        data: null,
        message: ERROR_MSG,
      };
    }
  };
  
  
