import styled from "styled-components";
import { COLOR } from "../shared/constant";

export const AddCurrencyWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 10px;
    margin-top: 60px;
`;

export const CurrencyWrapperLeft = styled.div`
    min-width: 400px;
    margin-bottom: 30px;
    border: 0.5px solid #d1d1d1;
    border: 0.5px solid ${COLOR.deepBlue};
   -webkit-box-shadow: 1px -3px 2px -1px #d1d1d1; 
   box-shadow: 1px -3px 2px -1px #d1d1d1;
`;

export const CurrencyWrapperRight = styled.div`
     /* width: 50%; */
`;

export const CurrencyListWrapperUL = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`

export const CurrencyListWrapperLI = styled.li`
    display: grid;
    grid-template-columns: 12;
    grid-template-rows: 1;
    border: 1px solid lightgrey;
    /* background-color: #f5f5f5; */
    margin: 10px;
    padding: 3px;
    border-radius: 3px;
`

export const CurrencyListWrapperLS = styled.div`
    grid-column: 1/9;
    grid-row: 1;
    
`

export const CurrencyListWrapperRS = styled.div`
     grid-column: 9/13;
     grid-row: 1;
     vertical-align: middle;
     text-align: right !important;
`


export const CurrencyBodyWrapper = styled.div`
   padding: 20px;
   padding-top: 0;
   min-width: 380px;
   margin-left: auto;
   margin-right: auto;
   margin-bottom: 40px;
   border: 0.5px solid ${COLOR.border};
   background-color: ${COLOR.white};
   border-radius: 0;
`
// Redundant block
export const RateListRow1Col1 = styled.div`
     grid-column: 1/12;
     grid-row: 1;
     vertical-align: middle;
     text-align: left !important;
`

export const RateListRow1Col1Flex = styled.div`
     display: flex;
     flex-direction: row;
     justify-content: flex-start;
     align-items: center;
     align-content: center;
     column-gap: 10px;
`

export const RateListRow1Col2 = styled.div`
     grid-column: 8/13;
     grid-row: 1;
     vertical-align: middle;
     text-align: right !important;
`