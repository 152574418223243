import React, { useState } from 'react'
import { LoginFormWrapper } from '../styles/login/login'
import { useForm } from 'react-hook-form';
import { loginService } from '../services/authService';

const LoginAdmin = (props: any) => {
    const { setIsLoggedIn, showNotification, setResponseData, setIsSubmit, isSubmit, setSelectedPage } = props;
    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const authenticateUser = async (data: any) => {
        setIsSubmit(true)
        data = {
            username: data.username,
            password: data.password
        }
        const response = await loginService(data);

        if (response.success) {
            setResponseData(response);
            setIsLoggedIn(true)
            setSelectedPage('createBill')
            showNotification(true, response.message)
            reset()
            setIsSubmit(false)
            return
        }
        setResponseData(response);
        setIsLoggedIn(false)
        showNotification(false, response.message)
        setIsSubmit(false)
        return
    };


    return (
        <LoginFormWrapper>
            <form onSubmit={handleSubmit(authenticateUser)} autoComplete='off' autoCapitalize='off'>

                <h3 style={{ color: '#0A3EA4', borderBottom: '0.5px solid #d1d1d1', fontWeight: 400, width: '100%', textAlign: 'center', paddingBottom: '20px' }}>
                    Enter login details
                </h3>

                <div style={{ marginBottom: '10px' }}>
                    <label>Username</label>
                    <input type="username" className='inputField' {...register("username", { required: true, pattern: /^\S+@\S+$/i })} />
                    {errors.username && <div className='errorText'>Username must be valid</div>}
                </div>

                <div style={{ marginBottom: '10px' }}>
                    <label>Password</label>
                    <input type="password" className='inputField' {...register("password", { required: true })} />
                    {errors.password && <div className='errorText'>Password is required</div>}
                </div>

                <div style={{ marginTop: '20px' }}>
                    <button type="submit" className='submitButton' disabled={isSubmit}>
                        {isSubmit ? 'Processing' : 'Sign In'}
                    </button>
                </div>
            </form>

        </LoginFormWrapper>
    )
}

export default LoginAdmin;