import styled from "styled-components";
import { COLOR } from "./constant";

export const NotificationBox = styled.div`
     position: fixed;
     bottom: 20px;
     left: 0;
     right: 0;
     width: 400px;
     margin-left: auto;
     margin-right: auto;
     padding: 15px;
     border-radius: 0;
     text-align: center;
     overflow: hidden;
     transition: all .5s ease-out;
`;

export const NotificationIcon = styled.div`
    
`;

export const NotificationText = styled.div`
    color: ${COLOR.white};
    font-size: 14.5px;
    font-weight: 400;
`;


