import styled from "styled-components";
import { COLOR } from "../shared/constant";


export const AdminMenuBox = styled.div`
    position: fixed;
    left: 10px;
    right: 10px;
    top: 63px;
    width: 100px;
    height: auto;
    padding: 5px;
    border-radius: 0 0 10px 10px;
    background-color: ${COLOR.border};
    -webkit-box-shadow: 6px -5px 14px 1px ${COLOR.deepBlue}; 
    box-shadow: 6px -5px 14px 1px ${COLOR.deepBlue};
    transition: max-height 0.3s ease-in-out; 
    -webkit-transition:  max-height 0.3s ease-in-out; 
    z-index: 2;
`

export const MenuPlaceHolder = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
`




